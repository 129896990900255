<template>
  <div id="IncompleteRemind">
    <PendingAction
      v-if="showIdentityProofAlert"
      v-bind:type="'identityProof'"
      v-on:clickedPendingAction="pendingActionResponse"
    ></PendingAction>

    <Notification
      v-if="pendingIDProofApproval && !showIdentityProofAlert"
      :id="pendingIDProofApproval"
      :poa="pendingAddressProofApproval"
    ></Notification>
    <UploadIdOrAddress
      :visible.sync="uploadIdOrAddress.visible"
      :idPendingReason="idPendingReason"
      :idCustomPendingReason="idCustomPendingReason"
      :poaPendingReason="poaPendingReason"
      :poaCustomPendingReason="poaCustomPendingReason"
      :idRecode="idRecode"
      :poaRecode="poaRecode"
    ></UploadIdOrAddress>
  </div>
</template>

<script>
import PendingAction from '@/components/home/PendingAction'
import Notification from '@/components/home/Notification'
import UploadIdOrAddress from '@/components/home/UploadIdAddress/UploadIdOrAddress'
import idPoaMixin from '@/mixins/idPoa'
export default {
  name: 'IncompleteRemind',
  components: {
    PendingAction,
    Notification,
    UploadIdOrAddress,
  },
  mixins: [idPoaMixin],
  data() {
    return {
      uploadIdOrAddress: {
        visible: false,
      },
    }
  },
  methods: {
    pendingActionResponse(type) {
      console.log('进来了')
      this.uploadIdOrAddress.visible = true
    },
  },
}
</script>

<style lang="scss" scoped></style>
